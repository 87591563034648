import React from 'react'
import Layout from '../components/layouts/page'
import { Helmet } from 'react-helmet'

const Contact = () => {
  return (
    <div className="page">
    <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Contact Devine Ride in Southern California</title>
    </Helmet>
    <Layout>
      <section className='bg-light'>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-8 text-center py-5'>
              <div className='py-5'>
                <h1 className='display-1 fw-medium mb-3'>How can we help?</h1>
                <p className='fs-5 mb-4'>For a quote request please give us a call or use this <a href='/quote'>dedicated form</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container-xxl py-5'>
            <div className='row py-5'>
              <div className='col-lg-6'>
                <h2 className='display-3 fw-medium mb-3'>Send us a message</h2>
                <form className="bg-light py-5 px-5" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="contact" value="contact" />
                  <div className="mb-2">
                    <label className="mb-1" for="field1">Name (required)
                    <input type="text" className="form-control form-control-lg rounded" id="field1" name="name" required/>
                    </label>
                  </div>
                  <div className="mb-2">
                    <label className="mb-1" for="field2">Email (required)
                    <input type="email" className="form-control form-control-lg rounded" id="field2" name="email" required/>
                    </label>
                  </div>
                  <div className="mb-2">
                  <label className="mb-1" for="field3">Phone (required)
                    <input type="text" className="form-control form-control-lg rounded" id="field3" name="phone" required/>
                    </label>
                  </div>
                  <div className="mb-2">
                    <label className="mb-1" for="field4">How did you hear about us?
                    <input type="text" className="form-control form-control-lg rounded" id="field4" name="source"/>
                    </label>
                  </div>
                  <div className="mb-3">
                    <label for="field5" className='mb-1'>How can we help? (required)
                    <textarea className="form-control form-control-lg rounded" id="field5" name="message" required/>
                    </label>
                  </div>
                  <button type="submit" class="btn btn-dark rounded">Send message</button>
                </form>
              </div>
              <div className='col-lg-6 mt-lg-0 mt-5'>
                <h2 className='display-3 fw-medium mb-3'>Other ways to contact us</h2>
                <ul className="list-group">
                  <li className="list-group-item d-flex align-items-center py-3">
                    <span className="w-25 text-muted">Email</span>
                    <a href="mailto:info@devineride.com" className="link-dark">info@devineride.com</a>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-3">
                    <span className="w-25 text-muted">Phone</span>
                    <a href="tel:9493558820" className="link-dark">(949) 355-8820</a>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-3">
                    <span className="w-25 text-muted">Facebook</span>
                    <a href="https://facebook.com/devineridelimo" className="link-dark">facebook.com/devineridelimo</a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </section>
    </Layout>
    </div>
  )
}

export default Contact